import React from "react";
import { Link } from "react-router-dom";
import useOnScrollReachElement from "../../../hooks/useOnScrollReachElement";
import Heading from "../../atoms/Heading";
import ImgParagraphRow from "../../molecules/ImgParagraphRow";
import Paragraph from "../../molecules/Paragraph";
import Card from "../../pageComponents/Home/Card";
import cogsImg from '../../../assets/images/Cogs.jpg'

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`;

const MiPyMes = () => {
  useOnScrollReachElement("MiPyMes");

  const Style = {
    MiPyMes: `WhiteSection SectionSeparator`,
    TipoPymesHeading: `mb76`,
    TipoPymesContainer: `flexWrap hCenter spaceAround gap28 mb56`,
  };

  return (
    <div id={`MiPyMes`} className={Style.MiPyMes}>
      <Heading numType={1} text={`APLICACIÓN PARA MIPYMES`} />
      <ImgParagraphRow
        ParagraphComponent={
          <>
            <Paragraph
              paragraphText={`El modelo de Microfactoria, asienta las bases de una serie de elementos que son muy beneficiosos para distintos sectores industriales y comerciales del país. Dejamos una reseña de cómo se ofrecen los diferentes modos de aplicación para la Microfactoria, con el objetivo de promover su utilización y dar acceso a los interesados en la temática. Destacamos dos campos de aplicación: EMPRESAS DEL SECTOR QUÍMICO y EMPRESAS DE OTROS MERCADOS.`}
            />
          </>
        }
        imgFirst={true}
        imgClasses={`h200 imgCenter cover noRepeat`}
        img={cogsImg}
        containerClasses={`SectionSeparator2`}
      />
      <Heading
        numType={2}
        classes={Style.TipoPymesHeading}
        text={`Empresas del sector químico:`}
      />
      <div className={Style.TipoPymesContainer}>
        <Card
          containerClasses={`m0`}
          intro={`Optimización y mejoramiento industrial para industrias que dispongan de una planta operativa:`}
          description={`Un sistema de estas características podría ser muy beneficioso para aumentar el volumen de producción sin tener que ampliar las instalaciones. Permite tener un sistema de producción mixto que sirva para cubrir picos de demanda. De ser requerido se puede migrar en su completitud a un sistema enteramente automático.`}
        />
        <Card
          containerClasses={`m0`}
          intro={`Ampliación del mercado meta:`}
          description={`La utilización de una Microfactoria reduce el riesgo de una expansión de mercado y los costos asociados. Permite poder crecer como empresa única, o bajo un modelo de franquicia, solo es necesario encargarse de los aspectos comerciales, todo lo relacionado con la parte técnica y química se encarga la Microfactoria. Reduce los costos logísticos de transporte de producto terminado y los costos asociados a la ampliación de instalaciones para la expansión. `}
        />
        <Card
          containerClasses={`m0`}
          intro={`Ampliación de producto:`}
          description={`La utilización de una Microfactoria permite la implementación de la filosofía refill, basándonos en los principios de la economía circular. Estas bases son completamente sólidas para ampliar las fronteras comerciales y brindar productos amigables con el medio ambiente. Para el caso de empresas que ya tengan una marca establecida en el mercado, la logística de implementación de esta ampliación de mercado sería muy económica, y permitiría acceder a un nuevo mercado que está en pujante crecimiento.`}
        />
        <Card
          containerClasses={`m0`}
          intro={`Diversificación`}
          description={`Combinando la tecnología de las Microfactorias con la filosofía refill, basándonos en los parámetros de la economía circular e incorporando productos biodegradables, se estaría diversificando el propio mercado. Esto permite alcanzar un mercado nuevo con un producto nuevo. La tendencia de consumo en este tipo de productos es creciente, y contar con una infraestructura que permita ser completamente sustentable hace posible mejorar aún más los beneficios que se pueden obtener con este mercado creciente.`}
        />
      </div>
      <Heading
        numType={2}
        classes={Style.TipoPymesHeading}
        text={`EMPRESAS DE OTROS MERCADOS:`}
      />
      <div className={Style.TipoPymesContainer}>
        <Card
          containerClasses={`m0`}
          intro={`Diversificación de producto, para empresas que desean aprovechar nuestras ventajas`}
          description={`La Microfactoría permite por su facilidad y simplicidad en el uso, poder ampliar las fronteras de producción para cualquier emprendedor o empresario del sector comercial. Sea por mera similitud de productos o por contar con un público de venta masivo. Es tan simple como tener una fotocopiadora y hacer fotocopias, no requiere de entrenamiento previo, ni tampoco de conocimientos en la materia química para hacerlo. Se puede ampliar la marca conocida por los clientes y ya exitosa en el mercado, y contar con una gama más amplia de productos a disposición, puesto que los productos de limpieza son de consumo recurrente, con esto se estaría optimizando resultados y maximizando ganancias.`}
        />
        <Card
          containerClasses={`m0`}
          intro={`Herramientas que ayuden a promover la adopción de la responsabilidad social empresarial:`}
          description={`Dar conocimiento de los criterios de economía circular, la importancia de la reutilización de envases plásticos, el aumento de la contaminación que estos envases de un solo uso genera al medio ambiente. Son una forma de promocionar políticas ambientales para la empresa y ampliar las estructuras de responsabilidad social empresarial. Claramente no hay mejor forma para divulgar una idea o concepto que la utilización práctica del mismo. Por tanto se puede contar con una Microfactoria que reciba envases en un contenedor inteligente, y a su vez detecte la cantidad de envases introducidos, otorgando producto a cambio de ello. Esto puede ser una forma muy intuitiva y elegante de fomentar el criterio de economía circular, favoreciendo a quienes reciclan con producto a elección como forma de intercambio. En estos casos es clave acompañar el uso del concepto de reutilización y las bases de la economía circular con una serie de seminarios relacionados en esta materia, para fortalecer los conocimientos en sustentabilidad y economía circular. Parámetros como la reutilización de los plásticos, vida de los mismos y cómo podemos ayudar desde nuestros hogares para disminuir el impacto que estos provocan al entorno son claves en este asunto.`}
        />
      </div>
    </div>
  );
};

export default MiPyMes;
